import '../assets/scss/style.scss'
import { Suspense } from 'react'
import {
  AppProps,
  AuthenticationError,
  AuthorizationError,
  ErrorBoundary,
  ErrorComponent,
  ErrorFallbackProps,
  useQueryErrorResetBoundary,
  useSession
} from 'blitz'
import { Banner } from '@shopify/polaris'
import { LoadingSpinner } from '@/core/components/LoadingSpinner'
import { UserInfo } from './login'

export default function App({ Component, pageProps }: AppProps) {
  const session = useSession({
    suspense: false
  })

  const getLayout = Component.getLayout || ((page) => page)

  return (
    <ErrorBoundary FallbackComponent={RootErrorFallback} onReset={useQueryErrorResetBoundary().reset}>
      {getLayout(<Component {...pageProps} />)}
    </ErrorBoundary>
  )
}

function RootErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  if (error instanceof AuthenticationError) {
    return (
      <>
        <div className="login-wrapper">
          <Suspense fallback={<LoadingSpinner size="large" />}>
            <UserInfo />
          </Suspense>
        </div>
      </>
    )
  } else if (error instanceof AuthorizationError) {
    return <ErrorComponent statusCode={error.statusCode} title="Sorry, you are not authorized to access this" />
  } else {
    return <ErrorComponent statusCode={error.statusCode || 400} title={error.message || error.name} />
  }
}
