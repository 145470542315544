import { Suspense } from 'react'
import { BlitzPage, Image, useMutation, useSession } from 'blitz'
import Layout from 'app/core/layouts/Layout'
import { useCurrentUser } from 'app/core/hooks/useCurrentUser'
import logout from 'app/auth/mutations/logout'
import logo from '/public/images/lf-customizer-logo.png'
import { LoadingSpinner } from '@/core/components/LoadingSpinner'

export const UserInfo = () => {
  const currentUser = useCurrentUser()
  const sesh = useSession()
  const [logoutMutation] = useMutation(logout)

  if (currentUser) {
    return (
      <>
        <button
          className="button small"
          onClick={async () => {
            await logoutMutation()
          }}>
          Logout
        </button>
        <div>
          <pre>{JSON.stringify(currentUser)}</pre>
          <pre>{JSON.stringify(sesh)}</pre>
        </div>
      </>
    )
  } else {
    return (
      <section className="login-content">
        <header>
          <h1>
            <Image src={logo} alt="Lingua Franca Customizer" width={399} height={92} />
          </h1>
        </header>
        <main>
          <a className="btn btn-wide btn-login" href={'/api/auth/google'}>
            <svg
              className="ico ico-google"
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24">
              <path
                fill="#4285F4"
                d="M23.745 12.27c0-.79-.07-1.54-.19-2.27h-11.3v4.51h6.47c-.29 1.48-1.14 2.73-2.4 3.58v3h3.86c2.26-2.09 3.56-5.17 3.56-8.82Z"
              />
              <path
                fill="#34A853"
                d="M12.255 24c3.24 0 5.95-1.08 7.93-2.91l-3.86-3c-1.08.72-2.45 1.16-4.07 1.16-3.13 0-5.78-2.11-6.73-4.96h-3.98v3.09C3.515 21.3 7.565 24 12.255 24Z"
              />
              <path
                fill="#FBBC05"
                d="M5.525 14.29c-.25-.72-.38-1.49-.38-2.29s.14-1.57.38-2.29V6.62h-3.98a11.86 11.86 0 0 0 0 10.76l3.98-3.09Z"
              />
              <path
                fill="#EA4335"
                d="M12.255 4.75c1.77 0 3.35.61 4.6 1.8l3.42-3.42C18.205 1.19 15.495 0 12.255 0c-4.69 0-8.74 2.7-10.71 6.62l3.98 3.09c.95-2.85 3.6-4.96 6.73-4.96Z"
              />
            </svg>
            Sign In With Google
          </a>
        </main>
        <footer>
          <p>
            Powered by{' '}
            <a href="https://smallplanet.com" title="Small Planet">
              Small Planet
            </a>
          </p>
        </footer>
      </section>
    )
  }
}

const LoginPage: BlitzPage = () => {
  return (
    <>
      <div className="login-wrapper">
        <Suspense fallback={<LoadingSpinner size="large" />}>
          <UserInfo />
        </Suspense>
      </div>
    </>
  )
}

LoginPage.suppressFirstRenderFlicker = true
LoginPage.getLayout = (page) => <Layout title="Home">{page}</Layout>

export default LoginPage
